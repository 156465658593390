import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
// routing

import { useNavigate } from 'react-router-dom';
import Routes from 'routes';
// defaultTheme
import themes from 'themes';
// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { AuthContext } from 'utils/AuthContext';
import { useState } from 'react';
import tokenService from 'api/token.service';
import instance from 'api/api';

// ==============================|| APP ||============================== //

const App = () => {
    const navigate = useNavigate();
    const customization = useSelector((state) => state.customization);
    const [LoginData, setLoginData] = useState(null);

    instance.setNavigate(navigate);

    const authContext = useMemo(() => {
        return {
            signIn: (data) => {
                tokenService.setUser(data);
                tokenService.setToken(data.token);
                setLoginData(data);
                navigate('', { replace: true });
            },
            signUp: (data) => {
                setLoginData(data);
            },
            signOut: (data) => {
                tokenService.removeUser();
                tokenService.removeToken();
                setLoginData(data);
                navigate('', { replace: true });
            }
        };
    }, []);

    useEffect(() => {
        var userData = tokenService.getUser();
        setLoginData(userData);
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <AuthContext.Provider value={authContext}>
                        <Routes LoginData={LoginData} />
                    </AuthContext.Provider>
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
