import axios from 'axios';
import TokenService from './token.service';

const instance = axios.create({
    baseURL: 'https://newapi.kayyel.com/app/v1/', //'https://newapi.kayyel.com/app/v1/',
    headers: {
        'Content-Type': 'application/json'
    }
});

let navigateCallback = null;

// Set the navigate callback (instance.setNavigate set on App.js)
instance.setNavigate = (navigate) => {
    navigateCallback = navigate;
};

instance.interceptors.request.use(
    (config) => {
        const token = TokenService.getToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        if (res.data.Error && res.data.Error.ErrorMessage) {
            let ErrorMessage = res.data.Error.ErrorMessage;
            if (
                res.data &&
                ((res.data.Error.ErrorCode === 10004 && res.data.Error.ErrorMessage === 'Invalid Auth Token') ||
                    (res.data.Error.ErrorCode === 10003 && res.data.Error.ErrorMessage === 'Auth Token Required'))
            ) {
                // Handle the case of an Invalid Auth Token or Auth Token Required
                TokenService.removeUser();
                TokenService.removeToken();
                // Redirect to the login page
                navigateCallback('/account/login');
                window.location.reload();
            }
            if (Array.isArray(ErrorMessage)) {
                ErrorMessage = ErrorMessage.join('\r\n');
            }
            alert(ErrorMessage);
        }
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        console.log('Error originalConfig', err);
        if (originalConfig.url !== 'login' && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    const rs = await instance.post('login', {
                        refreshToken: TokenService.getLocalRefreshToken()
                    });
                    const { AuthToken } = rs.data.Data;
                    TokenService.updateLocalAccessToken(AuthToken);
                    return instance(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
        }
        return Promise.reject(err);
    }
);
export default instance;
