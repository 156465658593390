class TokenService {
    getLocalRefreshToken() {
        const userData = localStorage.getItem('user');
        return userData && userData !== 'undefined' ? JSON.parse(userData)?.token : null;
    }
    getLocalAccessToken() {
        const userData = localStorage.getItem('user');
        return userData && userData !== 'undefined' ? JSON.parse(userData)?.token : null;
    }
    updateLocalAccessToken(token) {
        const userData = localStorage.getItem('user');
        if (userData && userData !== 'undefined') {
            let user = JSON.parse(localStorage.getItem('user'));
            user.token = token;
            localStorage.setItem('user', JSON.stringify(user));
        }
    }
    getUser() {
        const userData = localStorage.getItem('user');
        return userData && userData !== 'undefined' ? JSON.parse(userData) : null;
    }
    setUser(user) {
        localStorage.setItem('user', JSON.stringify(user));
    }
    removeUser() {
        localStorage.removeItem('user');
    }
    getToken() {
        const token = localStorage.getItem('authToken');
        return token && token !== 'undefined' ? JSON.parse(localStorage.getItem('authToken')) : token;
    }
    setToken(authToken) {
        localStorage.setItem('authToken', JSON.stringify(authToken));
    }
    removeToken() {
        localStorage.removeItem('authToken');
    }
}
export default new TokenService();
